<script setup>
import { closeModal } from '@kolirt/vue-modal'
import Register from '@/components/modals/Register.vue';
import Login from '@/components/modals/Login.vue';
import VerificationEmail from '@/components/modals/VerificationEmail.vue';
import { useCasinoStoreV2 } from '@/stores/casino_v2';
import { openModal } from '@kolirt/vue-modal';
import { useCustomerStore } from '@/stores/customer';
import { useGeneralStore } from '@/stores/general';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import _ from 'lodash';

const customerStore = useCustomerStore();
const casinoStore = useCasinoStoreV2();
const generalStore = useGeneralStore();
const router = useRouter();

const props = defineProps({
	game: Object,
	image_url: {
		type: String,
		default: '/assets/images/brand_logo.svg'
	},
})

// Show Register Modal
const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() => { });
};

// Show Login Modal
const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() => { });
};

// Show Verification Mail Modal
const showVerificationEmailModal = () => {
	openModal(VerificationEmail).catch(() => { });
};

// Toggle Favourite
const toggleFavourite = (game) => {
	var game_id = null;
	if (generalStore.isLicenseEnabled == 'true') game_id = game.slot_id;
	else game_id = game.game_id;

	if (!customerStore.isLoggedIn) {
		showLoginModal();

		return;
	}

	// Check if Customer has verified email
	if (!customerStore.hasVerifiedEmail) {
		showVerificationEmailModal();

		return;
	}

	casinoStore.toggleFavourite(game_id);
};

// Go To Game
const goToGame = (game, demo = false) => {
	var game_id = null;
	if (generalStore.isLicenseEnabled == 'true') game_id = game.id;
	else game_id = game.game_id;

	let params = {
		game_id: game_id,
	};

	if (demo) {
		params.demo = 'demo';
	}

	let router_object = { name: 'casino-game', params: params };

	// Check if Customer is logged in
	if (!customerStore.isLoggedIn) {
		useCustomerStore().redirect_after_login = router_object;

		showLoginModal();

		return;
	}

	// Check if Customer has verified email
	if (!customerStore.hasVerifiedEmail) {
		showVerificationEmailModal();

		return;
	}

	closeModal();

	// Go to Game's view
	router.push(router_object);
}

const isFavourite = computed(() => {
	// Set the table id
	let game_id = props.game.slot_id;

	// Get the customer's favourites
	let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

	// Check if the table is in the customer's favourites
	let is_favourite = _.find(favourites, { game_id: String(game_id) });

	// Return if the table is favourite or not
	return is_favourite ? true : false;
});
</script>
<template>
	<div id="customModal" class="custom-modal-detail">
		<div class="modal-content">

			<!-- Close Button Container -->
			<div class="close-container">
				<span id="closeModalBtn" class="close" @click="closeModal()">
					<svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="13.414" viewBox="0 0 13.414 13.414">
						<g id="close" transform="translate(0.707 0.707)">
							<line id="Line_2" data-name="Line 2" x2="12" y2="12" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
							<line id="Line_3" data-name="Line 3" x1="12" y2="12" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
						</g>
					</svg>
				</span>
			</div>

			<div class="slot-thumbnail default-image-container">
				<img v-if="props.image_url == '/assets/images/brand_logo.svg'" class="default-image" :src="props.image_url" alt="logo" />
				<img v-else :src="props.image_url" alt="logo" />
			</div>

			<div class="content">
				<p class="title-game-brand ellipsis">{{ props.game.label }}</p>
				<p class="title-game-name ellipsis">{{ props.game.name }}</p>
			</div>

			<div class="button-container">
				<div v-if="customerStore.isLoggedIn" @click="goToGame(props.game)" class="btn-play">{{ $t('general.play_now') }}</div>
				<div v-else @click="goToGame(props.game)" class="btn-play">{{ $t('general.login_and_play') }}</div>
				<div class="btn-favourite" @click.stop="toggleFavourite(props.game)">
					<svg v-if="isFavourite == true" xmlns="http://www.w3.org/2000/svg" width="12"
						height="11.143" viewBox="0 0 12 11.143">
						<path id="Icon_ionic-ios-star" data-name="Icon ionic-ios-star"
							d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z"
							transform="translate(-1.125 -1.688)" fill="#63A246" opacity="1"></path>
					</svg>
					<svg v-else xmlns="http://www.w3.org/2000/svg" width="12" height="11.143"
						viewBox="0 0 12 11.143">
						<path id="Icon_ionic-ios-star" data-name="Icon ionic-ios-star"
							d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z"
							transform="translate(-1.125 -1.688)" fill="#fff" opacity="0.403">
						</path>
					</svg>
				</div>
				<div class="btn-demo"  @click="goToGame(props.game, true)">{{ $t('general.demo') }}</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.custom-modal-detail .modal-content {
	background-color: #0C131A;
	padding: 0 0 15px;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
	text-align: center;
	position: relative;
	height: max-content;
	min-width: 300px;
	max-width: 100vw;
	margin: 0 auto;
	border: 1px solid #363D47;
}

.custom-modal-detail .modal-content .close-container {
	height: 25px;
	width: 25px;
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 5px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: background-color 0.3s ease;
	border: 1px solid #363D47;
}

.custom-modal-detail .modal-content .close-container:hover {
	background-color: rgba(0, 0, 0, 0.2);
	transform: scale(1.05);
}

.custom-modal-detail .modal-content .close {
	padding-bottom: 3px;
}

.custom-modal-detail .modal-content .close svg {
	width: 11px;
	height: 11px;
}

.title-game-name {
	font-size: 15px;
	color: #F2F2F7;
	font-weight: bold;
	margin-bottom: 5px;
}

.title-game-brand {
	font-size: 17px;
	color: #848484;
}

.custom-modal-detail .modal-content .slot-thumbnail img {
	width: auto;
	padding: 10px 10px 3px 10px;
	border-radius: 15px;
	max-height: 200px;
	object-fit: fill;
}

.custom-modal-detail .modal-content .content {
	font-size: 14px;
	color: #F2F2F7;
	margin-bottom: 10px;
	padding-left: 12px;
	padding-right: 12px;
}

.custom-modal-detail .modal-content .close-button:after {
	content: " ";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	margin: -1px;
	border-radius: inherit;
	background-image: linear-gradient(50deg, #e382bc 0%, #8d54eb 100%);
}

.default-image-container {
	background-color: rgb(0 0 0 / 20%);
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.default-image {
	width: auto !important;
	height: 200px !important;
	max-height: 185px;
	opacity: 0.3;
}

.button-container {
	display: inline-flex;
	gap: 5px;
	height: 33px;
}

.custom-modal-detail .modal-content .btn-play {
	position: relative;
	border-radius: 5px;
	cursor: pointer;
	margin: 0 auto;
	padding: 4px 33px;
	font-size: 14px;
	font-weight: 600;
	background: #63A246;
	color: #fff;
	border: 1px solid transparent;
	transition: 0.3s ease-in-out;
	margin-left: 10px;
}

.custom-modal-detail .modal-content .btn-play:hover {
	background: #4e8234;
	color: #ffffff;
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
	transform: translateY(-1px);
	border: 1px solid #4e8234;
}

.custom-modal-detail .modal-content .btn-favourite {
	position: relative;
	border-radius: 5px;
	cursor: pointer;
	margin: 0 auto;
	padding: 4px 10px;
	font-size: 14px;
	font-weight: 600;
	background: #202833;
	color: #fff;
	border: 1px solid transparent;
	transition: 0.3s ease-in-out;
}

.custom-modal-detail .modal-content .btn-favourite:hover {
	background: #2c3b4a;
	color: #ffffff;
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
	transform: translateY(-1px);
	border: 1px solid #2c3b4a;
}

.custom-modal-detail .modal-content .btn-favourite:hover svg {
	color: #ffffff;
	box-shadow: 0 8px 15px rgba(155, 59, 59, 0.1);
	transform: translateY(-1px);
}

.custom-modal-detail .modal-content .btn-demo {
	position: relative;
	border-radius: 5px;
	cursor: pointer;
	margin: 0 auto;
	padding: 4px 15px;
	font-size: 14px;
	font-weight: 600;
	background: #202833;
	color: #fff;
	border: 1px solid transparent;
	transition: 0.3s ease-in-out;
	margin-right: 10px;
}

.custom-modal-detail .modal-content .btn-demo:hover {
	background: #2c3b4a;
	color: #ffffff;
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
	transform: translateY(-1px);
	border: 1px solid #2c3b4a;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	max-width: 270px;
}
</style>
