<script setup>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { useCasinoStore } from '@/stores/casino';
import { ref } from 'vue';

// Initialize Store
const casinoStore = useCasinoStore();

// Initialize variables
const direction = ref(document.dir);

const options = ref({
	direction: direction,
	rewind: false,
	pagination: false,
	type:false,
	perMove: 1,
	autoWidth: true,
	breakpoints: {
		375: { perPage: 2 },
		550: { perPage: 2 },
		768: { perPage: 6 },
		1024: { perPage: 6 },
		1680: { perPage: 8 },
		2560: { perPage: 10 },
	},
});

const selectCategory = (category) => {
	casinoStore.selectCategory(category);
}

const handleSlotProviderSelectChange = (event) => {
	let slot_provider_id = event.target.value;

	if (slot_provider_id == '' || slot_provider_id == 'all') slot_provider_id = 'all';
	else slot_provider_id = Number(slot_provider_id);

	casinoStore.setSelectedSlotProviderId(slot_provider_id);
}
</script>

<template>
	<div class="container-lg casino-container-lg">
		<div class="casino-top-filters">
			<div class="casino-game_tab-outer live-casino-game-tab">
				<div class="casino-games-tab">
					<Splide :options="options">
						<div @click="selectCategory(null)"
							:class="casinoStore.getSelectedCategoryId == null ? 'active' : ''"
							class="game-filter all-filter">
							<div class="icon-box">
								<svg xmlns="http://www.w3.org/2000/svg" width="16.091" height="18.503"
									viewBox="0 0 16.091 18.503">
									<g id="Group_489" data-name="Group 489" transform="translate(0.25 0.25)">
										<g id="dice" transform="translate(0)">
											<path id="Path_529" data-name="Path 529"
												d="M55.081,15l-7.8,4.5v9l7.8,4.5,7.8-4.5v-9Z"
												transform="translate(-47.286 -15)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<path id="Path_530" data-name="Path 530"
												d="M62.877,19.5l-7.8,4.5-7.8-4.5,7.8-4.5Z"
												transform="translate(-47.286 -15)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_11" data-name="Line 11" y2="9.001"
												transform="translate(7.795 9.001)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_12" data-name="Line 12" x2="0.6"
												transform="translate(7.495 4.501)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_13" data-name="Line 13" y2="0.6"
												transform="translate(4.995 12.802)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_14" data-name="Line 14" y2="0.6"
												transform="translate(2.795 9.401)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_15" data-name="Line 15" y2="0.6"
												transform="translate(13.346 8.551)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_16" data-name="Line 16" y2="0.6"
												transform="translate(10.046 13.652)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
											<line id="Line_17" data-name="Line 17" y2="0.6"
												transform="translate(11.696 11.102)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
												stroke-width="0.5" />
										</g>
									</g>
								</svg>
							</div>
							<p class="nowrap">{{ $t('general.all_games') }}</p>
						</div>

						<SplideSlide v-for="category in casinoStore.getCategories" :key="category">
							<div @click="selectCategory(category)" class="game-filter"
								:class="casinoStore.getSelectedCategoryId == category.id ? 'active' : ''">
								<div class="icon-box">
									<img v-if="category.media" class="provider-logo" :src="casinoStore.getStorageUrl + category.media.path"/>
									<svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
										width="18" height="18" viewBox="0 0 18 18">
										<defs>
											<clipPath id="clip-path">
												<path id="path2055" d="M0-682.665H18v18H0Z"
													transform="translate(0 682.665)" />
											</clipPath>
										</defs>
										<g id="Group_526" data-name="Group 526" transform="translate(-465 -583)">
											<g id="g2049" transform="translate(465 583)">
												<g id="g2051">
													<g id="g2053" clip-path="url(#clip-path)">
														<g id="g2059" transform="translate(0.527 0.527)">
															<path id="path2061"
																d="M0-312.86a8.5,8.5,0,0,1,8.473-8.473,8.5,8.5,0,0,1,8.473,8.473,8.5,8.5,0,0,1-8.473,8.473A8.5,8.5,0,0,1,0-312.86Z"
																transform="translate(0 321.332)" fill="none"
																stroke="#fff" stroke-miterlimit="10"
																stroke-width="0.5" />
														</g>
														<g id="g2063" transform="translate(5.489 5.309)">
															<path id="path2065"
																d="M-129.637,0l1.24,1.985,2.271.566-1.5,1.793.164,2.335-2.17-.877-2.17.877.164-2.335-1.5-1.793,2.271-.566Z"
																transform="translate(133.148)" fill="none" stroke="#fff"
																stroke-miterlimit="10" stroke-width="0.5" />
														</g>
														<g id="g2067" transform="translate(2.672 2.672)">
															<path id="path2069"
																d="M0-233.671A6.328,6.328,0,0,1,6.328-240a6.328,6.328,0,0,1,6.328,6.328,6.328,6.328,0,0,1-6.328,6.328A6.328,6.328,0,0,1,0-233.671Z"
																transform="translate(0 239.999)" fill="none"
																stroke="#fff" stroke-miterlimit="10"
																stroke-width="0.5" />
														</g>
													</g>
												</g>
											</g>
										</g>
									</svg>
								</div>
								<p class="nowrap">{{ category.name }}</p>
							</div>
						</SplideSlide>
					</Splide>
				</div>
			</div>
			<div class="provider-option">
				<div class="provider-form-field">
					<select @change="handleSlotProviderSelectChange" class="form-input">
						<option class="form-input" value="all" selected disabled>{{ $t('general.select_your_provider') }}</option>
						<template v-for="(slot_provider) in casinoStore.getSlotProvidersFormatted(false)" :key="slot_provider">
							<option class="form-input" :value="slot_provider.id" :selected="slot_provider.id == casinoStore.getSelectedSlotProviderId">{{ slot_provider.name }}</option>
						</template>
					</select>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
li {
	margin-right: 5px;
}

.nowrap {
	white-space: nowrap;
}

.casino-game-area .casino-top-filters .casino-game_tab-outer.live-casino-game-tab {
	width: 100% !important;
	padding-left: 0 !important;
}

@media (min-width: 992px) {
	.casino-game-area .casino-top-filters {
		gap: 20px !important;
	}
}

html[dir="rtl"] .provider-option .provider-form-field select {
	background-position: 3% !important;
}
</style>