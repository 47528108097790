<script setup>
import { onMounted, onUnmounted } from 'vue';
import Loader from '@/components/casino/LoaderComponent.vue';
import FavoriteGames from '@/components/casino/FavoriteGames.vue';
import LastPlayed from '@/components/casino/LastPlayed.vue';
import Games from '@/components/casino/AllGames.vue';
import { useCasinoStore } from '@/stores/casino';

// Inialize store
const casinoStore = useCasinoStore();

const updateWidth = () => {
	casinoStore.setSnapshotGamesPerProvider(window.innerWidth);
};

onMounted(() => {
	window.addEventListener('resize', updateWidth);
	updateWidth();
});

onUnmounted(() => {
	window.removeEventListener('resize', updateWidth);
});
</script>
<template>
	<!-- Loader -->
	<Loader v-if="casinoStore.games_loading" />

	<template v-else>
		<!-- FavoriteGames -->
		<FavoriteGames v-if="casinoStore.showFavouritesGames && Object.keys(casinoStore.getGames).length > 0 && Object.keys(casinoStore.getFavouriteGames).length > 0"/>

		<!-- Last Played Games -->
		<LastPlayed v-if="!casinoStore.showFavouritesGames && casinoStore.selectedProvider == 'all' && !casinoStore.searchTerm && Object.keys(casinoStore.getGames).length > 0 && Object.keys(casinoStore.getLastPlayedGames).length > 0 && casinoStore.getSelectedCategoryId == null" />

		<!-- Games -->
		<Games v-if="!casinoStore.showFavouritesGames && Object.keys(casinoStore.getGames).length > 0" />

		<!-- No Games Available -->
		<div v-if="!casinoStore.showFavouritesGames && Object.keys(casinoStore.getGames).length == 0" class="main_cust_row no_games">
			<h6 class="text-color-and-align">{{ $t('general.slots_not_available') }}</h6>
		</div>
	</template>
</template>
<style scoped>
.text-color-and-align {
	text-align: center;
	color: #fff;
	font-weight: 300;
	margin-bottom: 50px;
	font-size: 20px;
	line-height: 200px;
}
</style>