<script setup>
/* eslint-disable */
import CasinoHome from '../components/casino/CasinoHome.vue';
import CasinoHomeV2 from '../components/casino_v2/CasinoHome.vue';
import { useGeneralStore } from '@/stores/general';

const generalStore = useGeneralStore();

</script>

<template>
<CasinoHome v-if="generalStore.isLicenseEnabled == 'false'"></CasinoHome>
<CasinoHomeV2 v-else></CasinoHomeV2>
</template>
