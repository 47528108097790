<script setup>
import { openModal } from '@kolirt/vue-modal';
import { onMounted } from 'vue';
import { useCasinoStoreV2 } from '@/stores/casino_v2'
import { useI18n } from 'vue-i18n';
import Login from '@/components/modals/Login.vue';
import Register from '@/components/modals/Register.vue';
import GameImage from '@/components/casino/GameImage.vue';

const { t } = useI18n();
const casinoStore = useCasinoStoreV2();

// Show Register Modal
const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() => { });
};

// Show Login Modal
const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() => { });
};

onMounted(() => {
	// Fetch games if not fetched
	if (Object.keys(casinoStore.getGames).length == 0) casinoStore.fetchGames();
	if (Object.keys(casinoStore.featuredSlotGamesFooter).length == 0) casinoStore.fetchFeaturedSlotGames({ number_of_games: 20 });
});
</script>
<template>
	<div class="casino-similar-games">
		<div class="promotion-header">
			<h2>{{ t('general.featured_games') }}</h2>
		</div>
		<div class="casino-similar-outer">

			<div class="feature-games-main">
				<div class="featured-game-inner" v-for="game in casinoStore.featuredSlotGamesFooter" :key="game.id">
					<div class="feature-image">
						<GameImage :game="game" :name="game.name" :size="'extra-small'"/>
					</div>
					<div class="feature-title">
						<h4 class="ellipsis">{{ game.name }}</h4>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<style scoped>
.casino_wrap .casino_detail_content .detail_main .right .right_content .play_all {
	height: 590px;
	grid-auto-rows: minmax(min-content, max-content);
}

.casino_wrap .casino_detail_content .detail_main .right .right_content .play_all .card {
	max-height: 136px;
}

.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
	width: 100%;
	height: 75px;
	overflow: hidden;
	border-radius: 5px;
}

.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
	display: grid;
	grid-template-columns: repeat(2, 48%);
	-moz-column-gap: 10px;
	column-gap: 10px;
	row-gap: 20px;
	overflow: auto;
}

@media screen and (min-width: 1280px) and (max-width: 1399px) {
	.casino-detail .casino-similar-games .casino-similar-outer {
		min-height: 650px !important;
	}
}

@media screen and (min-width: 991px) and (max-width: 1191px) {
	.casino-detail .casino-similar-games .casino-similar-outer {
		height: 650px !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 100px !important;
	}
}

@media screen and (max-width: 40px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(2, 48%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 102px !important;
	}
}

@media screen and (min-width: 401px) and (max-width: 450px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(2, 48) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 119px !important;
	}
}

@media screen and (min-width: 451px) and (max-width: 500px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(2, 48) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 135px !important;
	}
}

@media screen and (min-width: 501px) and (max-width: 550px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(3, 32.3%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 96px !important;
	}
}

@media screen and (min-width: 551px) and (max-width: 600px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(3, 32.3%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 100px !important;
	}
}

@media screen and (min-width: 601px) and (max-width: 650px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(3, 32.3%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 106px !important;
	}
}

@media screen and (min-width: 650px) and (max-width: 700px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(4, 24.5%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 91px !important;
	}
}

@media screen and (min-width: 701px) and (max-width: 767px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(4, 24.5%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 103px !important;
	}
}

@media screen and (min-width: 768px) and (max-width: 846px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(4, 24.5%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 125px !important;
	}
}

@media screen and (min-width: 846px) and (max-width: 900px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(5, 19.3%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 107px !important;
	}
}

@media screen and (min-width: 901px) and (max-width: 991px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(5, 19.3%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 118px !important;
	}
}

@media screen and (min-width: 1191px) and (max-width: 1279px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(5, 19.3%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 150px !important;
	}
}

@media screen and (min-width: 991px) and (max-width: 1000px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(2, 48%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 75px !important;
	}

	.casino-detail .sports-main-outer {
		grid-template-columns: 1fr;
	}
}

@media screen and (min-width: 1001px) and (max-width: 1100px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(2, 48%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 62px !important;
	}

	.casino-detail .sports-main-outer {
		grid-template-columns: 1fr;
	}
}

@media screen and (min-width: 1101px) and (max-width: 1190px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(2, 48%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 90px !important;
	}

	.casino-detail .sports-main-outer {
		grid-template-columns: 1fr;
	}
}

@media screen and (min-width: 1280px) and (max-width: 1599px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(2, 48%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 71px !important;
	}
}

@media screen and (min-width: 1600px) {
	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main {
		grid-template-columns: repeat(2, 48%) !important;
	}

	.casino-detail .casino-similar-games .casino-similar-outer .feature-games-main .featured-game-inner .feature-image {
		height: 78px !important;
	}
}

.featured-game-inner {
	background: #131C27 !important;
	padding: 10px !important;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}
</style>