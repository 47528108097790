<script setup>
import { useRoute, useRouter } from 'vue-router';
import { onBeforeMount, onUnmounted, ref, onMounted, watch } from 'vue';
import { useCasinoStoreV2 } from '@/stores/casino_v2';
import GameLoadingAndError from './GameLoadingAndError.vue';
import SlotFrameDarka from './SlotFrameDarka.vue';
import SlotFrameTomHorn from './SlotFrameTomHorn.vue';
import { useI18n } from 'vue-i18n';
import { useGeneralStore } from '../../../stores/general';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const casinoStore = useCasinoStoreV2();

// Define variables
const game_id = route.params.game_id;
const game_name = ref(null);
const provider = ref(null);
const loading = ref(true);
const error_message = ref(null);
const tomhorn_params = ref(null);

const iframe = ref(null);
const iframe_url = ref(null);
const slot_id = ref(null);

function isMobileDevice() {
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		return true;
	}

	return false;
}

function toggleBodyScrollBar(isFullscreen) {
	var doc = document.documentElement;
	var body = document.body;

	if (isFullscreen) {
		// Apply styles with !important to override existing styles
		doc.style.setProperty('overflow', 'hidden', 'important');
		body.style.setProperty('overflow', 'hidden', 'important');
	} else {
		// Reset styles
		doc.style.removeProperty('overflow');
		body.style.removeProperty('overflow');
	}
}

const toggleFullscreen = () => {
	var container = document.querySelector('.container');
	var navbar = document.querySelector('.navbar');
	var content = document.querySelector('.content');

	if (container.classList.contains('fullscreen-mode')) {
		container.classList.remove('fullscreen-mode');
		navbar.classList.remove('fullscreen-mode');
		content.classList.remove('fullscreen-mode');
		toggleBodyScrollBar(false);
	} else {
		container.classList.add('fullscreen-mode');
		navbar.classList.add('fullscreen-mode');
		content.classList.add('fullscreen-mode');
		toggleBodyScrollBar(true);
	}
}

const openGame = (game_id, demo = false) => {
	loading.value = true;
	error_message.value = null;

	casinoStore.openGame(game_id, demo).then((response) => {
		game_name.value = response.game_name;
		document.title = useGeneralStore().getGeneral.site_name + ' - ' + game_name.value;

		if (response.provider == 'tomhorn') {
			provider.value = 'tomhorn';
			if (response.error) {
				error_message.value = response.error;
			} else if (response.params) {
				tomhorn_params.value = response.params;
			}
		} else {
			if (isMobileDevice() === true) toggleBodyScrollBar(true);
			iframe.value = response.iframe;
			iframe_url.value = response.iframe_url;
			slot_id.value = response.slot_id
			error_message.value = null;
		}

		loading.value = false;
	}).catch((error) => {
		loading.value = false;
		iframe.value = '#';
		iframe_url.value = null;
		game_name.value = t('general.game_not_available');
		error_message.value = error.data.message;
		document.title = useGeneralStore().getGeneral.site_name + ' - ' + game_name.value;
	})
};

watch(() => router.currentRoute.value.params.game_id, (new_game_id) => {
	if (!new_game_id) return;
	if (new_game_id) openGame(new_game_id)
});

const goBack = () => {
	toggleBodyScrollBar(false);

	casinoStore.resetCasinoParameters();
	router.push({ name: 'casino' });
};

onMounted(() => {
	var iframe_height = 350 + 'px';
	if (isMobileDevice() === true) {
		var container = document.querySelector('.container');
		var navbar = document.querySelector('.navbar');
		var content = document.querySelector('.content');

		container.classList.add('fullscreen-mode');
		navbar.classList.add('fullscreen-mode');
		content.classList.add('fullscreen-mode');

		iframe_height = (window.innerHeight - 350) + 'px';
	}

	const documentHeight = () => {
		const doc = document.documentElement;
		doc.style.setProperty('--doc-height', iframe_height);
	}

	window.addEventListener('resize', documentHeight);
	documentHeight();

	window.addEventListener('message', function(event) {
		// Handle game closed message
		var data = event.data;

		if (data.status === 'closed') {
			location.href = '/casino';
		}
	});
});

onBeforeMount(() => {
	loading.value = true;

	let has_demo = false;

	if (router.currentRoute.value.params.demo) {
		has_demo = true;
	}

	// Open Game
	openGame(game_id, has_demo);
});

onUnmounted(() => {
	toggleBodyScrollBar(false);
});
</script>
<template>
	<div class="casino-detail-outer">
		<div class="container">
			<nav class="navbar">
				<button class="navbar-buttons back-button" @click="goBack()">
					<svg xmlns="http://www.w3.org/2000/svg" width="4.414" height="7.414" viewBox="0 0 4.414 7.414"><g id="Group_232" data-name="Group 232" transform="translate(0.707 0.525)"><line id="Line_7" data-name="Line 7" x1="3" y2="3" transform="translate(0 0.182)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"></line><line id="Line_8" data-name="Line 8" x1="3" y1="3" transform="translate(0 3.182)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"></line></g></svg> {{ $t('general.back') }}
				</button>
				<span class="game-title">{{ game_name }}</span>
				<button v-if="isMobileDevice() === false" class="navbar-buttons fullscreen-button"  @click="toggleFullscreen()">
					<svg class="svg-color" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
						<path fill-rule="evenodd"
							d="M1 1v6h2V3h4V1H1zm2 12H1v6h6v-2H3v-4zm14 4h-4v2h6v-6h-2v4zm0-16h-4v2h4v4h2V1h-2z" />
					</svg>
				</button>
			</nav>
			<div class="content">
				<GameLoadingAndError v-if="loading == true || error_message != null" :loading="loading"
					:error_message="error_message"></GameLoadingAndError>

				<SlotFrameDarka v-if="loading == false && iframe_url" :iframe="iframe" :iframe_url="iframe_url"
					:game_name="game_name" :error_message="error_message" :slot_id="slot_id"></SlotFrameDarka>

				<SlotFrameTomHorn v-if="loading == false && tomhorn_params" :params="tomhorn_params" :game_name="game_name">
				</SlotFrameTomHorn>
			</div>
		</div>
	</div>
</template>
<style scoped>
.casino-detail .casino-detail-main .casino-detail-outer .casino-detail-top .back-btn {
	color: #fff;
	text-decoration: none;
	font-size: 13px;
}

.svg-color {
	fill: #fff;
}

.container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	overflow: hidden;
}

.navbar {
	padding-bottom: 10px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
	.navbar {
		padding-bottom: 10px;
		display: grid;
		grid-template-columns: 1fr 200px 1fr!important;
		align-items: center;
	}
	.game-title {
		text-align: center;
		white-space: nowrap;
		overflow:hidden;
		text-overflow:ellipsis;
		display: block!important;
		margin-left: unset!important;
		margin-right: unset!important;
	}
}

.navbar-buttons {
	border: none;
	cursor: pointer;
	font-size: 0.9em;
	color: #fff;
	height: 20px;
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
}

html[dir="rtl"] .navbar-buttons {
	text-align: right !important;
}

.back-button {
	text-align: left;
}

.fullscreen-button {
	text-align: right;
}

html[dir="rtl"] .back-button {
	text-align: right !important;
}

html[dir="rtl"] .back-button svg {
	transform: rotate(-180deg);
}

html[dir="rtl"] .fullscreen-button {
	text-align: left !important;
}

.game-title {
	text-align: center;
	white-space: nowrap;
	color: #63a246;
	font-size: 38px;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
}

.content {
	width: 100%;
	min-height: 660px;
	height: var(--doc-height);
	position: relative;
	flex-grow: 1;
}

.iframe-content {
	width: 100%;
	height: 100%;
	border: none;
}

/* Virtual Fullscreen mode */
.container.fullscreen-mode {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	border: none;
	flex-direction: column;
	z-index: 9999;
	margin-left: 0px;
	padding-top: 0px !important;
}

.navbar.fullscreen-mode {
	background-color: #0C131A;
	color: #fff;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
	.container {
		margin-left: 0x;
	}

	.game-title {
		font-size: 20px;
	}

	.navbar-buttons {
		font-size: 0.7em;
	}

	.content {
		height: var(--doc-height);
	}
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
	.container {
		width: 100%;
		margin-left: 0px;
	}

	.casino_detail_content {
		padding: 104px 0 25px 0px !important;
	}


	.game-title {
		font-size: 18px;
		margin-top: 0px;
	}

}

@media only screen and (max-width: 900px) and (orientation: portrait) {
	.container {
		width: 100%;
		margin-left: 0px;
	}

	.casino_detail_content {
		padding: 104px 0 25px 0px !important;
	}

	.content {
		height: var(--doc-height);
	}

	.fullscreen-button {
		margin-right: 15px;
	}

	.game-title {
		font-size: 18px;
	}
}

@media only screen and (min-width: 832px) and (max-width: 991px) {
	.navbar {
		padding: 7px 0px 7px 0px;
	}
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
	.casino_detail_content {
		padding: 55px 0 75px 0px !important;
	}

	.content {
		min-height: unset;
	}
}

.game-title {
	font-size: 20px;
}
</style>
