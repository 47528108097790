
<script setup>
/* eslint-disable */

import { openModal } from '@kolirt/vue-modal';
import Login from '@/components/modals/Login.vue';
import Register from '@/components/modals/Register.vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import { useCasinoStore } from '@/stores/casino';
import { useCasinoStoreV2 } from '@/stores/casino_v2';
import { onMounted, ref } from 'vue';
import GameImage from '@/components/casino/GameImage.vue';
import { useGeneralStore } from '@/stores/general';

// Initialize Store
const generalStore = useGeneralStore();
const casinoStore = generalStore.isLicenseEnabled == 'false' ? useCasinoStore() : useCasinoStoreV2();

const direction = ref(document.dir);
const options = ref({
	direction: direction.value,
	rewind: false,
	pagination: false,
	perMove: 1,
	autoWidth: false,
	breakpoints: {
		375: { perPage: 1, gap: '12px', perMove: 1 },
		414: { perPage: 2, gap: '12px' },
		767: { perPage: 1, gap: '12px', perMove: 1, start: 0, autoWidth: true },
		991: { perPage: 3.5, gap: '12px', perMove: 1, autoWidth: true },
		1024: { perPage: 5, gap: '8px', perMove: 1 },
		1180: { perPage: 5, gap: '15px', perMove: 1 },
		1366: { perPage: 5, gap: '20px', perMove: 1 },
		1440: { perPage: 5, gap: '20px', perMove: 1 },
		1680: { perPage: 5, gap: '40px', perMove: 1 },
		1919: { perPage: 5, gap: '40px', perMove: 1 },
		1920: { perPage: 5, gap: '40px', perMove: 1, type: 'loop' },
		2560: { perPage: 5, gap: '40px', perMove: 1, type: 'loop', start: 0 },
	}
});

// Show Register Modal
const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() =>{});
};

// Show Login Modal
const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() => {});
};

onMounted(() => {
	// Fetch games if not fetched
	if (Object.keys(casinoStore.getGames).length == 0) casinoStore.fetchGames();
	if (Object.keys(casinoStore.featuredSlotGamesFooter).length == 0) casinoStore.fetchFeaturedSlotGames({ number_of_games: 10 });
});

</script>
<template>
<div class="games-slider-wrapper">
	<div class="games-slider-overflow">
		<Splide :options="options">
			<SplideSlide v-for="(game, index) in casinoStore.featuredSlotGames" :key="index" >
				<div class="games-slider-item carousel__item">
					<div class="games-slider-content">
						<div class="game-image-block">
							<GameImage :game="game" :size="'large'" :force_opacity="true"/>
						</div>
						<h6>{{ game.name }}</h6>
					</div>
				</div>
			</SplideSlide>
		</Splide>
	</div>
</div>
</template>
<style scoped>
html[dir="rtl"] .games-slider-wrapper .games-slider-overflow :deep(.splide .splide__arrows .splide__arrow--prev) {
	rotate: 180deg;
	right: -15px;
	left: unset;
}

html[dir="rtl"] .games-slider-wrapper .games-slider-overflow :deep(.splide .splide__arrows .splide__arrow--next) {
	rotate: 180deg;
	left: -15px;
	right: unset;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.games-slider-wrapper .games-slider-overflow :deep(.splide .splide__arrows .splide__arrow--prev) {
		left: -8px;
	}
	.games-slider-wrapper .games-slider-overflow :deep(.splide .splide__arrows .splide__arrow--next) {
		right: -8px;
	}
	html[dir="rtl"] .games-slider-wrapper .games-slider-overflow :deep(.splide .splide__arrows .splide__arrow--prev) {
		right: -10px;
	}

	html[dir="rtl"] .games-slider-wrapper .games-slider-overflow :deep(.splide .splide__arrows .splide__arrow--next) {
		left: -10px;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	html[dir="rtl"] .games-slider-wrapper .games-slider-overflow :deep(.splide .splide__arrows .splide__arrow--prev) {
		top: 35%;
	}

	html[dir="rtl"] .games-slider-wrapper .games-slider-overflow :deep(.splide .splide__arrows .splide__arrow--next) {
		top: 35%;
	}
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.games-slider-wrapper .games-slider-overflow .games-slider-item .games-slider-content .game-image-block {
		width: 100%;
	}

	.games-slider-wrapper .games-slider-overflow .games-slider-item .games-slider-content .game-image-block img {
		width: 180px;
	}

	.games-slider-wrapper .games-slider-overflow .splide .splide__slide {
		width: 180px;
	}
}

.game-image-block:hover {
	cursor: pointer;
}
</style>