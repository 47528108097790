<script setup>
import { closeModal, confirmModal } from '@kolirt/vue-modal'
import { useCasinoStoreV2 } from '@/stores/casino_v2';
import { useI18n } from 'vue-i18n';
import { ref, onMounted } from 'vue';
import _ from 'lodash';

const { t } = useI18n();

const casinoStore = useCasinoStoreV2();

const show_sort_popper = ref(false);
const selected_sort = ref('popular');
const selected_slot_provider_ids = ref([]);

onMounted(() => {
	selected_slot_provider_ids.value = _.cloneDeep(casinoStore.getSelectedSlotProviderIds);
});

const toggleSortPopper = () => {
	show_sort_popper.value = !show_sort_popper.value;
};

const sortPopular = () => {
	show_sort_popper.value = false;
	selected_sort.value = 'popular';
};

const sortAlphabetical = () => {
	show_sort_popper.value = false;
	selected_sort.value = 'alphabetical';
};

const clearSelections = () => {
	selected_slot_provider_ids.value = [];
};

const applySelections = () => {
	confirmModal({ selected_slot_provider_ids: selected_slot_provider_ids })
};

const toggleSelectedSlotProviderId = (slot_provider_id) => {
	const index = selected_slot_provider_ids.value.indexOf(slot_provider_id);
	if (index === -1) {
		selected_slot_provider_ids.value.push(slot_provider_id);
	} else {
		selected_slot_provider_ids.value.splice(index, 1);
	}
};
</script>

<template>
	<div id="customModal" class="custom-modal">
		<div class="modal-header">
			<h2 class="modal-title">
				<svg class="sort-svg mr-5px" xmlns="http://www.w3.org/2000/svg" width="18" height="15"
					viewBox="0 0 19 13" fill="none">
					<path d="M7.5 12.5H11.5V10.5H7.5V12.5ZM0.5 0.5V2.5H18.5V0.5H0.5ZM3.5 7.5H15.5V5.5H3.5V7.5Z"
						fill="#fff" />
				</svg>
				{{ t('general.providers') }}
			</h2>

			<Popper :show="show_sort_popper" title="Select Sort Method">
				<svg @click="toggleSortPopper" class="sort-svg mr-5px sort-margins" xmlns="http://www.w3.org/2000/svg"
					width="24" height="20" viewBox="0 0 15 12" fill="#fff">
					<path d="M9.54865 2.34275L0 2.34271V3.49266L9.54865 3.4927V2.34275Z" fill="#fff" />
					<path d="M6.09882 5.79258L0 5.79254V6.94249L6.09882 6.94253V5.79258Z" fill="#fff" />
					<path d="M2.64899 9.24242L0 9.24238V10.3923L2.64899 10.3924V9.24242Z" fill="#fff" />
					<path d="M12.4701 2.34275H11.3202V10.6223H12.4701V2.34275Z" fill="#fff" />
					<path
						d="M14.195 7.63249L11.8952 10.0474L9.59527 7.63249L8.79031 8.43746L11.0902 10.8523L11.8952 11.6573L12.7001 10.8523L15 8.43746L14.195 7.63249Z"
						fill="#fff" />
				</svg>

				<template #content>
					<div class="custom-popover">
						<button @click="sortPopular"
							:class="{ 'popover-button': true, 'selected': selected_sort === 'popular' }"
							class="popover-button">Popular</button>
						<button @click="sortAlphabetical"
							:class="{ 'popover-button': true, 'selected': selected_sort === 'alphabetical' }"
							class="popover-button">Alphabetical</button>
					</div>
				</template>
			</Popper>
			<span class="close-btn" @click="closeModal()">✕</span>
		</div>
		<div class="provider-grid">
			<template v-for="provider in casinoStore.getSlotProvidersFormatted(selected_sort)" :key="provider.id">
				<div class="provider-item" :class="selected_slot_provider_ids.includes(provider.id) ? 'active' : ''"
					@click="toggleSelectedSlotProviderId(provider.id)">
					<img v-if="provider.media" :src="casinoStore.getStorageUrl + provider.media.path"
						class="provider-logo" />
					<div v-else class="provider-name">{{ provider.name }}</div>
				</div>
			</template>
		</div>
		<div class="modal-footer">
			<button class="footer-btn clear-btn" @click="clearSelections">Clear</button>
			<button class="footer-btn apply-btn" @click="applySelections">Apply</button>
		</div>
	</div>
</template>

<style scoped>
.custom-modal {
	background-color: #1A1E24;
	border-radius: 8px;
	width: 50vw;
	max-width: 910px;
	padding: 20px;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
	position: relative;
	margin: 20px auto;
	z-index: 9999;
}

@media (min-width: 1024px) {
	.custom-modal {
		width: 58vw;
		padding: 20px;
	}
}

@media (max-width: 1023px) and (min-width: 769px) {
	.custom-modal {
		width: 83vw;
		padding: 15px;
	}
}

@media (max-width: 768px) {
	.custom-modal {
		width: 85vw;
		padding: 10px;
	}
}

@media (max-width: 480px) {
	.custom-modal {
		width: 90vw;
		padding: 5px;
	}
}

.modal-title {
	color: #FFF;
	flex-grow: 1;
	text-align: left;
	margin: 0;
	padding-left: 10px;
	font-size: 19px;
	font-weight: 400;
}

.modal-header {
	display: flex;
	justify-content: flex-end;
}

.close-btn {
	font-size: 20px;
	color: #FFFFFF;
	cursor: pointer;
}

.provider-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	gap: 10px;
	margin-top: 20px;
}

.provider-item {
	background-color: #2A2F37;
	border-radius: 5px;
	width: 100%;
	height: 35px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: background-color 0.2s;
}

.provider-item:hover {
	background-color: #3B4049;
}

.provider-logo {
	height: 15px;
}

.provider-name {
	color: #FFFFFF;
	font-size: 14px;
	text-align: center;
}

.modal-footer {
	display: flex;
	justify-content: flex-end;
	padding-top: 20px;
}

.footer-btn {
	padding: 5px 20px;
	margin-left: 10px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
	font-size: 14px;
	transition: background-color 0.3s;
}

.clear-btn {
	background-color: #555;
	color: #fff;
}

.clear-btn:hover {
	background-color: #666;
}

.apply-btn {
	background-color: #4CAF50;
	color: #fff;
}

.apply-btn:hover {
	background-color: #5CB85C;
}

.sort-svg {
	fill: #fff;
}

.sort-svg path {
	fill: #fff;
}

.sort-svg:hover {
	fill: #dddddd;
}

.sort-svg:hover path {
	fill: #dddddd;
}

.mr-5px {
	margin-right: 5px;
}

.sort-margins {
	margin-top: -8px !important;
	margin-bottom: -6px !important;
	margin-left: -8px !important;
	margin-right: 10px !important;
}

@media (max-width: 768px) {
	.provider-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

.active {
	background: #63A246;
	border: 1px solid transparent;
}

.custom-popover {
	display: flex;
	flex-direction: column;
	background: #2d2f33;
	border-radius: 5px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	padding: 8px;
	width: 150px;
}

.popover-button {
	color: #fff;
	background: #4a4c50;
	border: none;
	padding: 10px;
	margin-bottom: 5px;
	border-radius: 3px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
	transition: background-color 0.3s;
	font-size: 14px;
	font-weight: 300;
}

.popover-button:hover {
	background-color: #686b70;
}

.popover-button:last-child {
	margin-bottom: 0;
}

.selected {
	background-color: #686b70;
}
</style>
