<script setup>
import Header from '@/components/Common/header.vue';
import MobileMenu from '@/components/Common/mobileMenu.vue';
import Footer from "@/components/Common/Footer.vue";
import SimilarGames from '@/components/casino/game/SimilarGames.vue';
import SimilarGamesV2 from '@/components/casino_v2/game/SimilarGames.vue';
import GameWindow from '@/components/casino_v2/game/GameWindow.vue';
import { useGeneralStore } from '@/stores/general';

const generalStore = useGeneralStore();

</script>
<template>
	<div class="main_div">
		<!-- Header Start -->
		<Header></Header>
		<!-- Header End -->

		<!-- Mobile Menu Start -->
		<div class="fixed-btm-main">
			<div class="container-full fix-padding">
				<MobileMenu></MobileMenu>
			</div>
		</div>
		<!-- Mobile Menu End -->

		<div class="sports-main casino-detail">
			<div class="container-full">
				<div class="sports-main-outer">
					<!-- Game Frame Start -->
					<div class="casino-detail-main">
						<GameWindow></GameWindow>
					</div>
					<!-- Game Frame End -->

					<!-- Similar Games Start -->
					<div class="sports-outer-right casino-similar-main">
						<SimilarGames v-if="generalStore.isLicenseEnabled == 'false'"></SimilarGames>
						<SimilarGamesV2 v-else></SimilarGamesV2>
					</div>
					<!-- Similar Games End -->
				</div>

			</div>
		</div>
	</div>
	<Footer></Footer>
</template>
<style scoped>
.main_div {
	background-color: #202833;
}
html[dir="rtl"] .fix-padding {
	padding-left: unset;
	padding-right: unset;
}
html[dir="rtl"] .sports-main .container-full {
	padding-left: 0 !important;
	padding-right: 20px !important;
}
html[dir="rtl"] .sports-outer-right {
	border-left: unset;
	border-right: 1px solid #4E545D;
}
</style>