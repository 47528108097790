/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "../views/LandingPage.vue";
import Login from "@/components/modals/Login.vue";
import { openModal } from "@kolirt/vue-modal";
import EmailVerified from "../components/modals/EmailVerified.vue"
import Account from '../views/Account.vue';
import AccountDetails from '../components/account/account_details/AccountDetails.vue';
import { useCustomerStore } from "@/stores/customer";
import VerificationEmail from '@/components/modals/VerificationEmail.vue';
import { useAccountStore } from "@/stores/account";
import PlayerProtectionTimeLimit from '../components/account/player_protection/time_limits/TimeLimit.vue';
import PlayerProtectionTimeOut from '../components/account/player_protection/time_limits/TimeOut.vue';
import { useNavbarStore } from "@/stores/navbar_items";
import Affiliates from "../views/Affiliates.vue";
import { useSharedBetslipsStore } from "@/stores/shared_betslips";
import { BetSlip } from '@/stores/BetSlip';
import SharedBetslipError from "../components/modals/SharedBetslipError.vue"
import RacingPage from '../views/RacingPage.vue'
import RacingPageCoupons from '../views/RacingPageCoupons.vue'
import RacingPageFixtureBook from '../views/RacingPageFixtureBook.vue'
import { usePreSportsStore } from "@/stores/pre_sports";
import { useMenuStore } from "@/stores/menus";
import { useInitializeStore } from "@/stores/initialize";
import Register from "@/components/modals/Register.vue";
import { useMissionsStore } from "@/stores/missions";

// Pre Sports
import PreSportsContainer from "@/views/PreSportsContainer.vue";
import PreSportsHighlights from "@/views/PreSportsHighlights.vue";
import PreSportsCompetition from "@/views/PreSportsCompetition.vue";
import PreSportsCoupons from "@/views/PreSportsCoupons.vue";
import PreAllCoupons from "../../src/components/pre_sports/coupons/AllCoupons.vue";
import PreNextHours from "../../src/components/pre_sports/coupons/NextHours.vue";
import PreSportCouponsCompetitions from "@/views/PreSportCouponsCompetitions.vue";
import PreSportsFixtureBook from "@/views/PreSportsFixtureBook.vue";

// ESports
import ESportsContainer from "@/views/ESportsContainer.vue";
import ESportsUpcoming from "@/views/ESportsUpcoming.vue";
import ESportsLiveOverview from "@/views/ESportsLiveOverview.vue";
import ESportsCompetition from "@/views/ESportsCompetition.vue";
import ESportsCoupons from "@/views/ESportsCoupons.vue";
import EsportsAllCoupons from "../../src/components/esports/coupons/AllCoupons.vue";
import EsportsNextHours from "../../src/components/esports/coupons/NextHours.vue";
import ESportCouponsCompetitions from "@/views/ESportCouponsCompetitions.vue";
import ESportsFixtureBook from "@/views/ESportsFixtureBook.vue";
import ESportsAllUpcoming from "../../src/components/esports/highlights/AllUpcoming.vue";
import LiveEsportEventview from '@/views/ESportsLiveFixtureBook.vue';

// Live Sports
import LiveSportsContainer from "@/views/LiveSportsContainer.vue";
import LiveSportOverview from '../components/live_sports/LiveSportOverview';
import LiveSportEventview from '../components/live_sports/LiveSportEventView.vue';

// Live Casino
import LiveCasinoContent from "@/views/LiveCasinoContent.vue";
import LiveCasinoHome from "@/components/live_casino/LiveCasinoHome.vue";
import TableContainer from "@/components/live_casino/table/TableContainer.vue";

// Casino
import CasinoContent from "@/views/CasinoContent.vue";
import CasinoHome from "@/views/CasinoHome.vue";
import GameClose from "@/components/casino_v2/game/GameClose.vue";
import GameContainer from "@/components/casino_v2/game/GameContainer.vue";
import MissionsSidebar from "@/components/modals/missions/MissionsSidebar.vue";

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: "/",
			name: "landing",
			component: LandingPage,
		},
		{
			path: "/register",
			name: "register",
			component: LandingPage,
			beforeEnter: (to, from, next) => {
				router.push({ name: 'landing'});
				openModal(Register).catch(() =>{});
				next()
			},
		},
		{
			path: "/login",
			name: "login",
			component: LandingPage,
			beforeEnter: (to, from, next) => {
				router.push({ name: 'landing'});
				openModal(Login).catch(() =>{});
				next()
			},
		},
		{
			path: "/forgot-password",
			name: "forgotPassword",
			component: LandingPage,
			beforeEnter: (to, from, next) => {
				router.push({ name: 'landing'});
				openModal(Login, {
					forget_password: true
				}).catch(() =>{});
				next()
			},
		},
		{
			path: '/affiliates',
			name: 'affiliates',
			component: Affiliates
		},
		{
			path: "/password/reset/:token",
			name: "resetPassword",
			component: () => import("../views/Auth/resetPassword.vue"),
			meta: {
				title: "Forgot Password",
				auth: "guestOnly",
				transition: "fade",
			},
		},
		{
			path: "/password/reset/mobile/:token",
			name: "resetPasswordMobile",
			component: () => import("../views/Auth/resetPasswordMobile.vue"),
			meta: {
				title: "Forgot Password",
				auth: "guestOnly",
				transition: "fade",
			},
		},
		{
			path: '/account/unlock',
			name: 'accountUnlock',
			component: () => import("../views/Auth/resetPassword.vue"),
			meta: {
				title: "Account Unlock",
				auth: "guestOnly",
				transition: "fade",
			},
		},
		{
			path: '/account/unlock/mobile/:token',
			name: 'accountUnlockMobile',
			component: () => import("../views/Auth/resetPasswordMobile.vue"),
			meta: {
				title: "Account Unlock",
				auth: "guestOnly",
				transition: "fade",
			},
		},
		{
			path: '/email/verify/',
			name: 'emailVerify',
			component: LandingPage,
			beforeEnter: (to, from, next) => {
				openModal(EmailVerified).catch(() =>{});
				next()
			},
		},
		{
			path: "/pre-sports",
			component: PreSportsContainer,
			children: [
				{
					path: ':sport_id?/highlights',
					name: 'pre-sports-highlights',
					component: PreSportsHighlights,
				},
				{
					path: ':sport_id?/region/:region_id/competition/:competition_id?',
					name: 'pre-sports-competition',
					component: PreSportsCompetition,
				},
				{
					path: ':sport_id?/coupons/',
					component: PreSportsCoupons,
					children: [
						{
							path: 'all',
							name: 'pre-sports-coupons',
							component: PreAllCoupons,
						},
						{
							path: 'next-hours/:hours',
							name: 'pre-sports-coupons-next-hours',
							component: PreNextHours,
						}
					]
				},
				{
					path: ':sport_id/coupons/competitions/:competition_ids?',
					name: 'pre-sports-coupons-competitions',
					component: PreSportCouponsCompetitions,
				},
				{
					path: ':sport_id?/region/:region_id/competition/:competition_id?/fixture/:fixture_id?',
					name: 'pre-sports-fixturebook',
					component: PreSportsFixtureBook,
				},
			],
			beforeEnter: (to, from, next) => {
				const preSportsStore = usePreSportsStore();
				const menuStore = useMenuStore()
				const initializeStore = useInitializeStore()

				preSportsStore.selected_esport_id = null
				let device = initializeStore.isMobile == true ? 'mobile' : 'desktop'

				if (initializeStore.isSportsbookEnabled) {

					if (menuStore.isSportsActive) {
						next()
					} else {
						next({ name: 'landing' })
					}

				} else {
					next({ name: 'landing' })
				}
			}
		},
		{
			path: "/esports",
			component: ESportsContainer,
			children: [
				// {
				// 	path: ':sport_id?/Upcomming',
				// 	name: 'esports-upcoming',
				// 	component: ESportsUpcoming,
				// },
				{
					path: 'all/upcoming/',
					name: 'esports-all-upcoming',
					component: ESportsAllUpcoming,
				},
				{
					path: ':sport_id/upcoming',
					name: 'esports-upcoming',
					component: ESportsUpcoming,
				},
				{
					path: ':sport_id?/LiveOverview',
					name: 'esports-live',
					component: ESportsLiveOverview,
				},
				{
					path: ':sport_id?/region/:region_id/competition/:competition_id?',
					name: 'esports-competition',
					component: ESportsCompetition,
				},
				{
					path: ':sport_id?/coupons/',
					component: ESportsCoupons,
					children: [
						{
							path: 'all',
							name: 'esports-coupons',
							component: EsportsAllCoupons,
						},
						{
							path: 'next-hours/:hours',
							name: 'esports-coupons-next-hours',
							component: EsportsNextHours,
						}
					]
				},
				{
					path: ':sport_id/coupons/competitions/:competition_ids?',
					name: 'esports-coupons-competitions',
					component: ESportCouponsCompetitions,
				},
				{
					path: ':sport_id?/region/:region_id/competition/:competition_id?/fixture/:fixture_id?',
					name: 'esports-fixturebook',
					component: ESportsFixtureBook,
				},
				{
					path: "eventview/:fixture_id?",
					name: "live-Esports-eventview",
					component: LiveEsportEventview
				},

			],
			beforeEnter: (to, from, next) => {
				const preSportsStore = usePreSportsStore();

				const menuStore = useMenuStore()
				const initializeStore = useInitializeStore()

				let device = initializeStore.isMobile == true ? 'mobile' : 'desktop'

				if (initializeStore.isSportsbookEnabled) {

					if (menuStore.isESportsActive) {
						next()
					} else {
						next({ name: 'landing' })
					}

				} else {
					next({ name: 'landing' })
				}

				if (!to.params.sport_id) {
					preSportsStore.fetchPreSports().then((response) => {
						next({ ...to, params: { ...to.params, sport_id: preSportsStore.esports[0].id } });
					});
				} else if (to.params.sport_id == '' || to.params.sport_id == null) {
					next();
				} else {
					preSportsStore.fetchPreSports().then((response) => {
						let valid_sport = preSportsStore.esports.some(sport => sport.id === Number(to.params.sport_id));

						if (valid_sport) next();
						else {
							router.push({'name': to.name.replace('esports', 'pre-sports'), params: {sport_id: to.params.sport_id}})
						}
					})
				}
			}
		},
		{
			path: "/live-sports",
			// redirect: { name: "live_sports.overview" },
			component: LiveSportsContainer,
			beforeEnter: (to, from, next) => {
				const menuStore = useMenuStore()
				const initializeStore = useInitializeStore()

				let device = initializeStore.isMobile == true ? 'mobile' : 'desktop'

				if (initializeStore.isSportsbookEnabled) {

						if (menuStore.isLiveSportsActive) {
							next()
						} else {
							next({ name: 'landing' })
						}
				} else {
					next({ name: 'landing' })
				}
			},
			children: [
				{
					path: "overview",
					name: "live-sports-overview",
					component: LiveSportOverview
				},
				{
					path: "eventview/:fixture_id?",
					name: "live-sports-eventview",
					component: LiveSportEventview
				},
			],
		},
		{
			path: "/live-casino",
			name: "LiveCasino",
			component: LiveCasinoContent,
			beforeEnter: (to, from, next) => {
				const menuStore = useMenuStore()
				const initializeStore = useInitializeStore()

				let device = initializeStore.isMobile == true ? 'mobile' : 'desktop'

				if (initializeStore.isLiveCasinoEnabled) {
					if (menuStore.isLiveCasinoActive) {
						next()
					} else {
						next({ name: 'landing' })
					}
				} else {
					next({ name: 'landing' })
				}
			},
			children: [
				{
					path: '',
					name: 'live-casino',
					component: LiveCasinoHome,
				},
				{
					path: 'table/:table_id/:param_id?',
					name: 'live-casino-table',
					component: TableContainer,
					beforeEnter: (to, from, next) => {
						const customerStore = useCustomerStore()

						if (customerStore.isLoggedIn) {
							if (!customerStore.hasVerifiedEmail) {
								router.push({ name: 'live-casino'})

								openModal(VerificationEmail).catch(() =>{});
								return
							}
						} else if (!customerStore.isLoggedIn) {
							router.push({ name: 'live-casino'})
							return
						}
						next()
					},
				},
			]
		},
		{
			path: "/casino",
			name: "casino-main",
			component: () => import("@/views/CasinoContent.vue"),
			beforeEnter: (to, from, next) => {
				const menuStore = useMenuStore()
				const initializeStore = useInitializeStore()

				let device = initializeStore.isMobile == true ? 'mobile' : 'desktop'

				if (initializeStore.isCasinoEnabled) {

					if (menuStore.isCasinoActive) {
						next()
					} else {
						next({ name: 'landing' })
					}
				} else {
					next({ name: 'landing' })
				}
			},
			children: [
				{
					path: '',
					name: 'casino',
					component: () => import("@/views/CasinoHome.vue"),
				},
				{
					path: 'category/:category_slug',
					name: 'casino-category',
					component: () => import("@/views/CasinoHome.vue"),
				},
				{
					path: 'favourites',
					name: 'casino-favourites',
					component: () => import("@/views/CasinoHome.vue"),
				},
				{
					path: 'last-played',
					name: 'casino-last-played',
					component: () => import("@/views/CasinoHome.vue"),
				},
				{
					path: 'close',
					name: 'casino-close',
					component: () => import("@/components/casino_v2/game/GameClose.vue"),
				},
				{
					path: 'game/:game_id/:demo?',
					name: 'casino-game',
					component: () => import("@/components/casino_v2/game/GameContainer.vue"),
					beforeEnter: (to, from, next) => {
						const customerStore = useCustomerStore()

						if (customerStore.isLoggedIn) {
							if (!customerStore.hasVerifiedEmail) {
								router.push({ name: 'casino'});

								openModal(VerificationEmail).catch(() => {});

								return;
							}
						} else if (!customerStore.isLoggedIn) {
							// Create params
							let params = {
								game_id: to.params.game_id,
								demo: to.params.demo
							};

							// Create router object
							let router_object = { name: 'casino-game', params: params };

							// Save object to local storage
							useCustomerStore().redirect_after_login = router_object;

							openModal(Login).catch(() =>{ });

							return;
						}
						next()
					},
				},
			]
		},
		{
			path: "/promotions",
			name: "promotions",
			component: () => import("@/views/Promotions.vue"),
		},
		{
			path: "/promotion/:promotion_slug",
			name: "promotion",
			component: () => import("@/components/promotions/Promotion.vue"),
		},
		{
			path: "/missions/overview/:mission_id?",
			name: "missions",
			component: () => import("@/views/Missions.vue"),
		},
		{
			path: "/missions",
			name: "missions-popup",
			component: LandingPage,
		},
		{
			path: '/account',
			component: Account,
			meta: {
				auth: 'verifiedOnly'
			},
			beforeEnter: (to, from, next) => {
				const accountStore = useAccountStore();
				const customerStore = useCustomerStore()
				// const betslipModalStore = useBetslipModalStore();

				if (!customerStore.isLoggedIn) {
					router.push({ name: 'landing'});

					openModal(Login);
					return;
				}

				if (!customerStore.hasVerifiedEmail) {
					router.push({ name: 'landing'});

					openModal(VerificationEmail).catch(() =>{});
					return;
				}

				if (to.path.startsWith('/account') && !from.path.startsWith('/account')) {
					accountStore.setPreviousRoute(from.path)
					// if (betslipModalStore.modalOpen) betslipModalStore.toggleModal();
				}

				next();
			},
			children: [

				// Account Details
				{
					path: 'account-details/details',
					name: 'account-details',
					component: AccountDetails,
				},
				{
					path: 'account-mobile/account-mobile-dashboard',
					name: 'account-mobile-dashboard',
					component: () => import("../components/account/account_mobile/AccountMobile.vue"),
				},
				{
					path: 'account-details/password',
					name: 'account-details-password',
					component: () => import("../components/account/account_details/Password.vue"),
				},
				{
					path: 'account-details/preferences',
					name: 'account-details-preferences',
					component: () => import("../components/account/account_details/Preferences.vue"),
				},
				{
					path: 'account-details/verification-process',
					name: 'account-details-verification-process',
					component: () => import("../components/account/account_details/VerificationProcess.vue"),
					beforeEnter: (to, from, next) => {
						const is_country_ghana = process.env.VUE_APP_COUNTRY == 'GHANA';

						if (is_country_ghana) {
							next({ name: 'landing' })
							return;
						}

						next();
					},
				},

				// Bank
				{
					path: 'bank/deposits',
					name: 'bank-deposits',
					component: () => import("../components/account/bank/Deposits.vue"),
					children: [
						{
							path: 'plisio',
							name: 'bank-deposits-plisio',
							component: () => import("../components/account/bank/payment_gateways/deposit/Plisio.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-deposits-plisio-success',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Success.vue"),
								},
								{
									path: 'failed',
									name: 'bank-deposits-plisio-failed',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Failed.vue"),
								},
							]
						},
						{
							path: 'coinpayments',
							name: 'bank-deposits-coinpayments',
							component: () => import("../components/account/bank/payment_gateways/deposit/CoinPayments.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-deposits-coinpayments-success',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Success.vue"),
								},
								{
									path: 'failed',
									name: 'bank-deposits-coinpayments-failed',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Failed.vue"),
								},
							]
						},
						{
							path: 'hubtel',
							name: 'bank-deposits-hubtel',
							component: () => import("../components/account/bank/payment_gateways/deposit/Hubtel.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-deposits-hubtel-success',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Success.vue"),
								},
								{
									path: 'failed',
									name: 'bank-deposits-hubtel-failed',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Failed.vue"),
								},
							]
						},
						{
							path: 'iran-voucher',
							name: 'bank-deposits-iran-voucher',
							component: () => import("../components/account/bank/payment_gateways/deposit/IranVoucher.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-deposits-iran-voucher-success',
								},
								{
									path: 'failed',
									name: 'bank-deposits-iran-voucher-failed',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Failed.vue"),
								}
							]
						},
						{
							path: '0xprocessing',
							name: 'bank-deposits-0xprocessing',
							component: () => import("../components/account/bank/payment_gateways/deposit/0xProcessing.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-deposits-0xprocessing-success',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Success.vue"),
								},
								{
									path: 'failed',
									name: 'bank-deposits-0xprocessing-failed',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Failed.vue"),
								},
							]
						},
						{
							path: 'central-pay',
							name: 'bank-deposits-central-pay',
							component: () => import("../components/account/bank/payment_gateways/deposit/CentralPay.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-deposits-central-pay-success',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Success.vue"),
								},
								{
									path: 'failed',
									name: 'bank-deposits-central-pay-failed',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Failed.vue"),
								},
							]
						},
						{
							path: 'perfect-money',
							name: 'bank-deposits-perfect-money',
							component: () => import("../components/account/bank/payment_gateways/deposit/PerfectMoney.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-deposits-perfect-money-success',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Success.vue"),
								},
								{
									path: 'failed',
									name: 'bank-deposits-perfect-money-failed',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Failed.vue"),
								},
							]
						},
						{
							path: 'perfect-money-voucher',
							name: 'bank-deposits-perfect-money-voucher',
							component: () => import("../components/account/bank/payment_gateways/deposit/PerfectMoneyVoucher.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-deposits-perfect-money-voucher-success',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Success.vue"),
								},
								{
									path: 'failed',
									name: 'bank-deposits-perfect-money-voucher-failed',
									component: () => import("../components/account/bank/payment_gateways/deposit/redirects/Failed.vue"),
								},
							]
						},
					]
				},
				{
					path: 'bank/withdrawals',
					name: 'bank-withdrawals',
					component: () => import("../components/account/bank/Withdrawals.vue"),
					children: [
						{
							path: 'plisio',
							name: 'bank-withdrawals-plisio',
							component: () => import("../components/account/bank/payment_gateways/withdraw/Plisio.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-withdrawals-plisio-success',
									component: () => import("../components/account/bank/payment_gateways/withdraw/redirects/Success.vue"),
								},
							]
						},
						{
							path: 'coinpayments',
							name: 'bank-withdrawals-coinpayments',
							component: () => import("../components/account/bank/payment_gateways/withdraw/CoinPayments.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-withdrawals-coinpayments-success',
									component: () => import("../components/account/bank/payment_gateways/withdraw/redirects/Success.vue"),
								},
								{
									path: 'failed',
									name: 'bank-withdrawals-coinpayments-failed',
									component: () => import("../components/account/bank/payment_gateways/withdraw/redirects/Failed.vue"),
								},
							]
						},
						{
							path: 'hubtel',
							name: 'bank-withdrawals-hubtel',
							component: () => import("../components/account/bank/payment_gateways/withdraw/Hubtel.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-withdrawals-hubtel-success',
									component: () => import("../components/account/bank/payment_gateways/withdraw/redirects/Success.vue"),
								},
							]
						},
						{
							path: 'iran-voucher',
							name: 'bank-withdrawals-iran-voucher',
							component: () => import("../components/account/bank/payment_gateways/withdraw/IranVoucher.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-withdrawals-iran-voucher-success',
								}
							]
						},
						{
							path: '0xprocessing',
							name: 'bank-withdrawals-0xprocessing',
							component: () => import("../components/account/bank/payment_gateways/withdraw/0xProcessing.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-withdrawals-0xprocessing-success',
									component: () => import("../components/account/bank/payment_gateways/withdraw/redirects/Success.vue"),
								},
							]
						},
						{
							path: 'central-pay',
							name: 'bank-withdrawals-central-pay',
							component: () => import("../components/account/bank/payment_gateways/withdraw/CentralPay.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-withdrawals-central-pay-success',
									component: () => import("../components/account/bank/payment_gateways/withdraw/redirects/Success.vue"),
								},
							]
						},
						{
							path: 'perfect-money',
							name: 'bank-withdrawals-perfect-money',
							component: () => import("../components/account/bank/payment_gateways/withdraw/PerfectMoney.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-withdrawals-perfect-money-success',
									component: () => import("../components/account/bank/payment_gateways/withdraw/redirects/Success.vue"),
								},
							]
						},
						{
							path: 'perfect-money-voucher',
							name: 'bank-withdrawals-perfect-money-voucher',
							component: () => import("../components/account/bank/payment_gateways/withdraw/PerfectMoneyVoucher.vue"),
							children: [
								{
									path: 'success',
									name: 'bank-withdrawals-perfect-money-voucher-success',
									component: () => import("../components/account/bank/payment_gateways/withdraw/redirects/Success.vue"),
								},
							]
						},
					]
				},
				{
					path: 'bank/pending-transactions',
					name: 'bank-pending-transactions',
					component: () => import("../components/account/bank/Pending.vue"),
				},
				// // History
				{
					path: 'history/settled-bets',
					name: 'history-settled-bets',
					component: () => import("../components/account/history/SettledBets.vue"),
				},
				{
					path: 'history/unsettled-bets',
					name: 'history-unsettled-bets',
					component: () => import("../components/account/history/UnsettledBets.vue"),
				},
				{
					path: 'history/slots',
					name: 'history-slots',
					component: () => import("../components/account/history/Slots.vue"),
				},
				{
					path: 'history/live-casino',
					name: 'history-live-casino',
					component: () => import("../components/account/history/LiveCasino.vue"),
				},
				{
					path: 'history/deposits',
					name: 'history-deposits',
					component: () => import("../components/account/history/Deposits.vue"),
				},
				{
					path: 'history/withdrawals',
					name: 'history-withdrawals',
					component: () => import("../components/account/history/Withdrawals.vue"),
				},

				// Messages
				{
					path: 'messages',
					name: 'account-messages',
					component: () => import("../components/account/messaging/Messages.vue"),
					children: [
						{
							path: 'message/:message_id',
							name: 'message',
							component: () => import("../components/account/messaging/Message.vue"),
						},

					]
				},
				{
					path: 'message/:message_id',
					name: 'message-mobile',
					component: () => import("../components/account/messaging/MessageMobile.vue"),

				},
				{
					path: 'message/new_message_mobile',
					name: 'new-message-mobile',
					component: () => import("../components/account/messaging/NewMessageMobile.vue"),

				},
				// Bonus
				{
					path: 'bonus',
					component: () => import("../components/account/bonus/Bonus.vue"),
					children: [
						{
							path: 'active-bonuses',
							name: 'bonus-active-bonuses',
							// component: BonusActiveBonuses
							component: () => import("../components/account/bonus/ActiveBonuses.vue"),
						},
						{
							path: 'available-bonuses',
							name: 'bonus-available-bonuses',
							component: () => import("../components/account/bonus/AvailableBonuses.vue"),
						},
						{
							path: 'history',
							name: 'bonus-history',
							component: () => import("../components/account/bonus/BonusHistory.vue"),
						},
					]
				},

				// Player Protection
				{
					path: 'player-protection/limits',
					component: () => import("../components/account/player_protection/Limits.vue"),
					children: [
						{
							path: 'login-limit',
							name: 'player-protection-login-limit',
							component: () => import("../components/account/player_protection/limits/LoginLimit.vue"),
						},
						{
							path: 'deposit-limits',
							name: 'player-protection-deposit-limits',
							component: () => import("../components/account/player_protection/limits/DepositLimits.vue"),
						},
						{
							path: 'bet-limits/sportbook-limit',
							name: 'bet-limits-sportbook-limit',
							component: () => import("../components/account/player_protection/limits/SportbookLimit.vue"),
						},
						{
							path: 'bet-limits/casino-limit',
							name: 'bet-limits-casino-limit',
							component: () => import("../components/account/player_protection/limits/CasinoLimit.vue"),
						},
						{
							path: 'loss-limits',
							name: 'player-protection-loss-limits',
							component: () => import("../components/account/player_protection/limits/LossLimits.vue"),
						},
					]
				},
				{
					path: 'player-protection/cancel-withdraw-request',
					name: 'player-protection-cancel-withdraw-request',
					component: () => import("../components/account/player_protection/CancelWithdrawRequest.vue"),
				},
				{
					path: 'player-protection/reality-check',
					name: 'player-protection-reality-check',
					component: () => import("../components/account/player_protection/RealityCheck.vue"),
				},
				// Time Limits
				{
					path: 'player-protection/time-limits',
					component: () => import("../components/account/player_protection/TimeLimits.vue"),
					children: [
						{
							path: 'time-limit',
							name: 'player-protection-time-limit',
							component: PlayerProtectionTimeLimit,
						},
						{
							path: 'time-out',
							name: 'player-protection-time-out',
							component: PlayerProtectionTimeOut,
						},
					]
				},
				{
					path: 'player-protection/self-exclusion',
					name: 'player-protection-self-exclusion',
					component: () => import("../components/account/player_protection/SelfExclusion.vue"),
				},
				// Notifications
				{
					path: 'notifications',
					name: 'account-notifications',
					component: () => import("../components/account/notifications/Notifications.vue"),
				},
			]
		},
		{
			path: '/static',
			component: () => import("../views/StaticPage.vue"),
			children: [
				{
					path: 'page/:static_page_slug?',
					name: 'static-content',
					component: () => import("../components/Common/pageMainContenStatic.vue"),
				},
			],
		},
		{
			path: '/404',
			name: 'not-found',
			component: () => import("../components/Common/Error404.vue"),
		},
		// {
		// 	path: '/race',
		// 	name: 'racing',
		// 	component: () => import("../views/RacingPage.vue"),
		// },
		{
			path: '/race_detail',
			name: 'racing_detail',
			component: () => import("../views/RacingPage_detail.vue"),
		},
		{
			path: "/racing",
			component: RacingPage,
			beforeEnter: (to, from, next) => {
				const menuStore = useMenuStore()
				const initializeStore = useInitializeStore()

				let device = initializeStore.isMobile == true ? 'mobile' : 'desktop'

				if (initializeStore.isSportsbookEnabled) {
					if (menuStore.isRacingSportsActive) {
						next()
					} else {
						next({ name: 'landing' })
					}
				} else {
					next({ name: 'landing' })
				}
			},
			children: [
				{
					path: ':sport_id?/coupons',
					name: 'racing-coupons',
					component: RacingPageCoupons,
				},
				{
					path: ':sport_id?/region/:region_id/competition/:competition_id?/fixture/:fixture_id?',
					name: 'racing-fixture-book',
					component: RacingPageFixtureBook,
				},
			]
		},
		{
			path: '/:pathMatch(.*)*',
			component: () => import("../components/Common/Error404.vue"),
		},
		{
			path:'/share/betslip/:id',
			name: 'sharedBetslip',
			component: LandingPage,
			beforeEnter: (to, from, next) => {
				const id = to.params.id
				const sharedBetslipStore = useSharedBetslipsStore();
				const betslipStore = BetSlip();

				betslipStore.resetBetslip();
				sharedBetslipStore.fetchSharedBetslip(id).then((response) => {
					let bets = JSON.parse(response)

					let must_delete = false;
					for (let index in bets) {
						let bet = bets[index];

						betslipStore.toggleToBetslip({ odd_uuid: bet.bet_uuid, fixture_id: bet.fixture_id }, id).catch(error => {
							if (!must_delete) {
								sharedBetslipStore.deleteSharedBetslip(id);
								must_delete = true;
							}
						});
					}

				}).catch((error) => {
					openModal(SharedBetslipError, {errors: error.response, need_redirect: true}).catch(() =>{});
				})

				betslipStore.betslip_deleted = false
				next()
			}
		}
	],
	scrollBehavior(to, from, savedPosition) {
		const casino_routes = ['casino', 'casino-category', 'casino-favourites', 'casino-last-played'];

		if (casino_routes.includes(to.name)) {
			return savedPosition;
		} else {
			return { top: 0 };
		}
	}
});

router.beforeEach(async (to, from, next) => {
	const customerStore = useCustomerStore();

	// Redirect if needed
	if (useCustomerStore().auth_token == null) customerStore.redirect_after_login = {};
	let has_redirect_request = useCustomerStore().redirect_after_login;
	if (customerStore.isLoggedIn && has_redirect_request && Object.keys(has_redirect_request).length > 0) {
		customerStore.redirect_after_login = {};

		router.push(has_redirect_request).catch(err => {
		  next();
		});
	}

	if (!customerStore.auth_token) {
		to.meta.auth == "authOnly" || to.meta.auth == "verifiedOnly" ? next("/") : next();
	}

	if (customerStore.auth_token) {
		if (to.meta.auth == "guestOnly"){
			next("/")
		} else {
			if (to.meta.auth == "verifiedOnly") {
				if (!customerStore.hasVerifiedEmail) {
						openModal(VerificationEmail).catch(() =>{});
						next();
				} else {
					next();
				}
			} else {
				next();
			}
		}
	}
});

router.beforeEach((to, from, next) => {
	const navbarStore = useNavbarStore();
	navbarStore.closeAll()

	next();
});

export default router;
