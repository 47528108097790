<script setup>
import { openModal } from '@kolirt/vue-modal';
import { ref, computed, watch } from 'vue';
import { useCustomerStore } from '@/stores/customer';
import Login from '@/components/modals/Login.vue';
import Register from '@/components/modals/Register.vue';
import VerificationEmail from '@/components/modals/VerificationEmail.vue';
import GameOverlayModal from '@/components/casino_v2/game/GameOverlayModal.vue';
import { useRouter } from 'vue-router';
import { useCasinoStoreV2 } from '@/stores/casino_v2';
import { useGeneralStore } from '@/stores/general';

const router = useRouter();
const customerStore = useCustomerStore();
const casinoStore = useCasinoStoreV2();
const generalStore = useGeneralStore();

// Props
const props = defineProps({
	game: {
		type: Object,
		required: true
	},
	size: {
		type: String,
		default: 'small'
	},
	force_opacity: {
		type: Boolean,
		default: false
	},
});

// On mobile show modal instead of overlay
const showOverlayModal = (image_url) => {
	openModal(GameOverlayModal, { game: props.game, image_url: image_url }).catch(() => { });
};

// Show Register Modal
const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() => { });
};

// Show Login Modal
const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() => { });
};

// Show Verification Mail Modal
const showVerificationEmailModal = () => {
	openModal(VerificationEmail).catch(() => { });
};

const has_error = ref(false);
const setError = () => {
	has_error.value = true;
};

const hasError = computed(() => {
	return has_error.value;
});

const imageUrl = computed(() => {
	if (generalStore.isLicenseEnabled == 'false') return props.game.image_url;

	let url = props.game.image_url;

	if (url != null && url != '' && generalStore.isLicenseEnabled == 'true' && !url.startsWith('http')) url = process.env.VUE_APP_URL + url;

	const url_pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,6})(\/[\S]*)?$/
	let pattern_test = url_pattern.test(url);

	if (!url || url == '' || url == null || url == undefined || !pattern_test || !isImageUrlValid.value) {
		return '/assets/images/brand_logo.svg';
	}

	return url;
});

const isDefaultImage = computed(() => {
	if (imageUrl.value == '/assets/images/brand_logo.svg' || has_error.value == true) {
		return true;
	}

	return false;
});


const isImageUrlValid = computed(() => {
	let url = props.game.image_url;
	let url_valid = true;

	// Check if the URL is empty or null
	if (!url || url == '') {
		url_valid = false;
	} else {
		// Try to create a new URL object to verify URL format
		try {
			new URL(url);
		} catch (e) {
			url_valid = false; // URL is not valid
		}

		// Extract the filename and validate it
		if (url_valid) {
			const fileName = url.split('/').pop();
			if (!fileName || fileName.includes('..')) {
				url_valid = false; // Filename is not valid
			}

			// Regular expression to check for valid image file extensions
			const validImageRegex = /\.(jpeg|jpg|png|svg|gif)$/i;
			if (!validImageRegex.test(fileName)) {
				url_valid = false; // File extension is not valid
			}
		}
	}

	if (!url_valid) {
		return false;
	}
	return true;
});

const visibilityChanged = (is_visible, entry) => {
	if (is_visible) {
		const img_element = entry.target;
		const data_src = img_element.getAttribute('data-src');
		if (data_src) {
			img_element.setAttribute('src', data_src);
			img_element.removeAttribute('data-src');
		}
	}
};

// Go To Game
const goToGame = (game, demo = false) => {
	var game_id = null;
	if (generalStore.isLicenseEnabled == 'true') game_id = game.id;
	else game_id = game.game_id;

	let params = {
		game_id: game_id,
	};

	if (demo) {
		params.demo = 'demo';
	}

	let router_object = { name: 'casino-game', params: params };

	// Check if Customer is logged in
	if (!customerStore.isLoggedIn) {
		useCustomerStore().redirect_after_login = router_object;

		showLoginModal();

		return;
	}

	// Check if Customer has verified email
	if (!customerStore.hasVerifiedEmail) {
		showVerificationEmailModal();

		return;
	}

	// Go to Game's view
	router.push(router_object);
}

const selectPlayButtonClass = computed(() => {
	if (props.size == 'extra-small') {
		return 'play-button-extra-small';
	} else if (props.size == 'small') {
		return 'play-button-small';
	} else if (props.size == 'large') {
		return 'play-button-large';
	}

	return 'play-button-small';
});

const selectDemoButtonClass = computed(() => {
	if (props.size == 'small') {
		return 'demo-text-small';
	} else if (props.size == 'large') {
		return 'demo-text-large';
	}

	return 'demo-text-small';
});

const selectFavContainerClass = computed(() => {
	if (props.size == 'extra-small') {
		return 'favourite-container-extra-small';
	} else if (props.size == 'small') {
		return 'favourite-container-small';
	} else if (props.size == 'large') {
		return 'favourite-container-large';
	}

	return 'favourite-container-small';
});

const selectFavClass = computed(() => {
	if (props.size == 'extra-small') {
		return 'fav-svg-extra-small';
	} else if (props.size == 'small') {
		return 'fav-svg-small';
	} else if (props.size == 'large') {
		return 'fav-svg-large';
	}

	return 'fav-svg-small';
});

// Toggle Favourite
const toggleFavourite = (game) => {
	if (!customerStore.isLoggedIn) {
		showLoginModal();

		return;
	}

	// Check if Customer has verified email
	if (!customerStore.hasVerifiedEmail) {
		showVerificationEmailModal();

		return;
	}

	casinoStore.toggleFavourite(game.id);
};

const openOverlay = (image_url) => {
	if (isMobileDevice()) {
		if (!hasError.value) showOverlayModal(image_url);
		else showOverlayModal('/assets/images/brand_logo.svg');
	}
};

function isMobileDevice() {
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		return true;
	}

	return false;
}

watch(() => props.game.image_url, (newVal, oldVal) => {
	if (newVal != oldVal) has_error.value = false;
});
</script>
<template>
	<div @click="openOverlay(imageUrl)" :class="['image-container', isDefaultImage ? 'default-image-container' : '', props.force_opacity == true ? 'default-image-container-dark' : '']">
		<img v-if="!hasError"
			:src="imageUrl"
			:data-src="imageUrl"
			:class="{'game-image-hover': true, 'default-image': isDefaultImage}"
			:alt="props.game.name"
			@error="setError()"
			v-observe-visibility="visibilityChanged">

		<img v-else
			src="/assets/images/brand_logo.svg"
			data-src="/assets/images/brand_logo.svg"
			:alt="props.game.name"
			class="default-image game-image-hover"
			v-observe-visibility="visibilityChanged">


		<div v-if="!isMobileDevice()" class="overlay" :class="isDefaultImage ? 'overlay-light' : ''">
			<div :class="selectFavContainerClass" class="favourite-container" @click.stop="toggleFavourite(props.game)">
				<svg v-if="props.game.favourite == true" :class="selectFavClass" xmlns="http://www.w3.org/2000/svg" width="12" height="11.143" viewBox="0 0 12 11.143">
					<path id="Icon_ionic-ios-star" data-name="Icon ionic-ios-star" d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z"	transform="translate(-1.125 -1.688)" fill="#63A246" opacity="1"></path>
				</svg>
				<svg v-else :class="selectFavClass" xmlns="http://www.w3.org/2000/svg" width="12" height="11.143" viewBox="0 0 12 11.143">
					<path id="Icon_ionic-ios-star" data-name="Icon ionic-ios-star" d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z" transform="translate(-1.125 -1.688)" fill="#fff" opacity="0.5"></path>
				</svg>
			</div>

			<button class="play-button-container">
				<div class='play-button' :class="selectPlayButtonClass" @click="goToGame(props.game)">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" width="213.7px" height="213.7px" viewBox="0 0 213.7 213.7" enable-background="new 0 0 213.7 213.7"	xml:space="preserve">
						<polygon class='triangle' id="XMLID_18_" fill="none" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="73.5,62.5 148.5,105.8 73.5,149.1 " />
						<circle class='circle' id="XMLID_17_" fill="none" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="106.8" cy="106.8" r="103.3" />
					</svg>
				</div>
			</button>
			<div v-if="game.has_demo == true" class="demo-text" :class="selectDemoButtonClass" @click="goToGame(props.game, true)">{{ $t('general.demo') }}</div>
		</div>
	</div>
</template>

<style scoped>
.default-image-container {
	/* opacity: 0.2; */
	background-color: rgb(0 0 0 / 20%);
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.default-image-container-dark {
	background-color: rgb(0 0 0 / 80%);
}

.default-image {
	width: auto !important;
	height: 85% !important;
	max-height: 185px;
	opacity: 0.3;
}

.game-image-hover {
	height: 100%;
	transition: transform 0.3s ease;
	object-fit: fill !important;
}

.image-container:hover .game-image-hover {
	transform: scale(1.1);
}

.image-container {
	position: relative;
	width: 100%;
	height: 100%;
}

.image-container .overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s, visibility 0.3s;
}

.image-container .overlay-light {
	background-color: rgba(0, 0, 0, 0.5);
}

.image-container:hover .overlay {
	/* .image-container .overlay { */
	opacity: 1;
	visibility: visible;
}

.play-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.demo-text {
	margin-top: 8px;
	color: white;
	font-size: 13px;
	transition: transform 0.3s ease-in-out;
}

.demo-text-small {
	margin-top: 7px;
	font-size: 12px;
}

.demo-text-large {
	margin-top: 16px;
	font-size: 15px;
}

.demo-text:hover {
	color: rgb(204, 204, 204);
}
</style>

<style lang="scss" scoped>
.circle {
	stroke: #a5a5a5;
	stroke-dasharray: 650;
	stroke-dashoffset: 0;
	-webkit-transition: all 0.5s ease-in-out;
	opacity: 1;
}

.play-button svg {
	height: 40px;
	width: 40px;
}

.play-button {
	height: 40px;
	width: 40px;
	display: inline-block;
	-webkit-transition: all 0.5s ease;

	.triangle {
		-webkit-transition: all 0.7s ease-in-out;
		stroke-dasharray: 240;
		stroke-dashoffset: 0;
		stroke: #a5a5a5;
		transform: translateY(0) scale(1);
	}

	&:hover {

		.triangle {
			stroke-dashoffset: 480;
			opacity: 1;
			stroke: #a5a5a5;
			animation: nudge 0.7s ease-in-out;
			transform: translateY(0) scale(1.3);
			transform-origin: center;

			@keyframes nudge {
				0% {
					transform: translateX(0) scale(1);
				}

				30% {
					transform: translateX(-5px) scale(1);
				}

				50% {
					transform: translateX(5px);
				}

				70% {
					transform: translateX(-2px) scale(1);
				}

				100% {
					transform: translateX(0) scale(1.3);
				}
			}
		}

		.circle {
			stroke-dashoffset: 650;
			opacity: 0.3;
		}

	}
}

.play-button-extra-small {
	height: 30px;
	width: 30px;
}

.play-button-extra-small svg {
	height: 30px;
	width: 30px;
}

.play-button-small {
	height: 35px;
	width: 35px;
}

.play-button-small svg {
	height: 35px;
	width: 35px;
}

.play-button-large svg {
	height: 60px;
	width: 60px;
}

.play-button-large {
	height: 60px;
	width: 60px;
}

.favourite-container {
	position: absolute;

	background-color: rgba(0, 0, 0, 0.5);
	padding: 5px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.favourite-container-extra-small {
	top: 0px;
	right: 0px;
}

.favourite-container .fav-svg-extra-small {
	width: 12px;
	height: 12px;
}

.favourite-container-small {
	top: 8px;
	right: 8px;
}
.favourite-container .fav-svg-small {
	width: 14px;
	height: 14px;
}


.favourite-container-large {
	top: 15px;
	right: 15px;
}

.favourite-container .fav-svg-large {
	width: 18px;
	height: 18px;
}

.favourite-container:hover svg {
	opacity: 0.8;
}
</style>