<script setup>
import { onMounted } from 'vue';
import BrandsSlider from '@/components/casino/BrandsSlider.vue';
import GamesContainer from '@/components/casino/GamesContainer.vue';
import CategorySlider from '@/components/casino/CategorySlider.vue';
import { useCustomerStore } from '@/stores/customer';
import { useCasinoStore } from '@/stores/casino';
import { useGeneralStore } from '@/stores/general';
import { useI18n } from 'vue-i18n';
import ScrollToTop from '@/components/casino/ScrollToTop.vue';

// Inialize store
const casinoStore = useCasinoStore();
const customer = useCustomerStore();
const generalStore = useGeneralStore();
const { t } = useI18n();

onMounted(() => {
	document.title = generalStore.getGeneral.site_name + ' - ' + t('routing.casino');

	document.body.style.overflow = 'auto';

	// Fetch games and last played games
	if (casinoStore.getGamesCount == 0) {
		casinoStore.fetchGames();

		if (customer.isLoggedIn) casinoStore.fetchLastPlayedGames();
	}
});
</script>
<template>
	<!-------- Brand Slider Start --------->
	<div class="casino-tabing-header">
		<div class="container-lg casino-container-lg">
			<BrandsSlider></BrandsSlider>
		</div>
	</div>
	<!-------- Brand Slider End--------->

	<!---- Games Section Start ---->
	<div class="casino-game-area">
		<!-- Categories Slider Start -->
		<CategorySlider v-if="generalStore.isLicenseEnabled == 'true'"></CategorySlider>

		<div class="casino-game-section">
			<GamesContainer></GamesContainer>
		</div>
	</div>
	<!---- Games Section End ---->

	<ScrollToTop></ScrollToTop>
</template>
<style scoped>
.casino-game-area {
	padding-bottom: 0;
}
</style>