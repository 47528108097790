<script setup>
import { ref } from 'vue';
import { useCasinoStore } from '@/stores/casino';
import GameImage from '@/components/casino/GameImage.vue';
import Loader from '@/components/casino_v2/LoaderComponent.vue';

const casinoStore = useCasinoStore();

let show_more_loading = ref({});

// Toggle Show More
const showMoreToggle = () => {
	show_more_loading.value['show_favourites_games'] = true;

	// Toggle show more
	casinoStore.showMoreToggle('show_favourites_games').then(() => {
		show_more_loading.value['show_favourites_games'] = false;
	}).catch(() => {
		show_more_loading.value['show_favourites_games'] = false;
	});

	// If provider is expanded or all provider's games are 13 or less games do not scroll to provider's top
	if (casinoStore.getShowMoreProvider.includes('show_favourites_games') || casinoStore.favoriteGamesCount <= casinoStore.getSnapshotGamesPerProvider) {
		return;
	}
};
</script>
<template>
	<div class="container-lg casino-container-lg">
		<!-- Casino Games Start -->

		<div id="favourites" class="casino-feature-games">
			<div class="title">
				<div class="category-title-container">
					<h3 class="category-title-name">{{ $t('general.favourites') }}</h3>
					<span class="category-title-count">({{ casinoStore.favoriteGamesCount }})</span>
				</div>
			</div>

			<div class="casino-feature-outer">
				<div class="feature-games-main">
					<template v-for="game, game_index in casinoStore.getFavouriteGames" :key="game_index">
						<div class="featured-game-inner" :class="{ 'first-game': game_index == 0 }">
							<div class="feature-image" :class="{ 'feature-image-large': game_index == 0 }">
								<GameImage v-if="game_index == 0" :game="game" :size="'large'" />
								<GameImage v-else :game="game" />
							</div>
							<div class="feature-title">
								<h4 class="ellipsis">{{ game.name }}</h4>
								<span class="provider-name fs-10px">{{ game.label }}</span>
							</div>
						</div>
					</template>
				</div>
			</div>

			<!-- Show More/Less Button Start -->
			<div class="view-all-container">
				<div class="view-all-divider"></div>
				<button v-if="casinoStore.favoriteGamesCount > casinoStore.getSnapshotGamesPerProvider" @click="showMoreToggle('show_favourites_games')" class="btn-small view-all-button fw-300 h-30px">
					<span>{{ casinoStore.getShowMoreProvider.includes('show_favourites_games') ? $t('general.less') : $t('general.more') }}</span>
				</button>
			</div>
			<!-- Show More/Less Button End -->
		</div>

		<Loader v-show="casinoStore.getGamesLoadingMore == true" />
		<!-- Casino Games End -->
	</div>
</template>
<style scoped>
.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer {
	display: grid;
	grid-template-columns: 1fr !important;
	gap: 12px;
	padding-top: 13px;
	grid-template-rows: auto;
	align-items: start;
}

.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main {
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	grid-auto-rows: minmax(100px, auto);
	gap: 10px;
}


@media (min-width: 300px) and (max-width: 540px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: 540px) and (max-width: 670px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (min-width: 671px) and (max-width: 991px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (min-width: 992px) and (max-width: 1100px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main {
		grid-template-columns: repeat(5, 1fr) !important;
	}
}

@media (min-width: 1101px) and (max-width: 1200px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main {
		grid-template-columns: repeat(6, 1fr) !important;
	}
}

@media (min-width: 1201px) and (max-width: 1359px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main {
		grid-template-columns: repeat(6, 1fr) !important;
	}
}

@media (min-width: 1360px) and (max-width: 1599px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main {
		grid-template-columns: repeat(6, 1fr) !important;
	}
}

.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main .first-game {
	grid-column: 1 / 3;
	grid-row: 1 / 3;
}

.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main .featured-game-inner:nth-child(n+13) {
	display: unset;
}

@media screen and (min-width: 320px) and (max-width: 1800px) {
	.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main .featured-game-inner:nth-child(n+5) {
		display: unset;
	}
}

.feature-image-large {
	width: auto !important;
	height: 278px !important;
	overflow: hidden !important;
	display: flex !important;
	justify-content: center !important;
	border-radius: 5px !important;
}

.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main .featured-game-inner:nth-child(n+13) {
	display: unset !important;
}

.view-all-btn {
	text-align: right;
}

.view-all-btn:before {
	left: 0;
}

html[dir="rtl"] .view-all-btn {
	text-align: left !important;
}

html[dir="rtl"] .view-all-btn:before {
	right: 0 !important;
}

.text-color-and-align {
	text-align: center;
	color: #fff;
}

.casino-feature-games:first-child {
	padding-top: 30px !important;
}

.container-lg {
	padding-bottom: 30px;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

.casino-feature-games {
	overflow-x: hidden;
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
}

.view-all-btn-full-width:before {
	width: 100% !important;
}

.feature-image-large {
	margin-left: auto;
	margin-right: auto;
}

.badge-container {
	display: flex;
	flex-wrap: wrap;
	/* Allows badges to wrap onto the next line */
	background-color: transparent;
	padding: 5px;
	margin-top: 10px;
	gap: 5px;
	/* Adds spacing between badges */
}

.badge {
	background-color: #555;
	color: white;
	border-radius: 15px;
	padding: 5px 10px;
	display: flex;
	align-items: center;
	font-size: 13px;
	margin-bottom: 5px;
	/* Adds space between rows when wrapping */
}

.close-button {
	font-size: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	cursor: pointer;
	background-color: #777;
	color: #ccc;
	transition: background-color 0.3s, color 0.3s;
	border: none;
	margin-left: 5px;
}

.close-button i {
	line-height: 1;
}

.close-button:hover {
	background-color: #999;
	color: white;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.icon-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.sort-svg {
	margin-left: 10px;
	/* Creates space between the two SVGs */
	cursor: pointer;
	/* Optional, adds pointer cursor on hover */
}

/* Ensure SVG fills the space appropriately */
.sort-svg:first-child {
	margin-right: 5px;
	/* Adds a little space between the two icons */
}

.custom-popover {
	display: flex;
	flex-direction: column;
	background: #2d2f33;
	border-radius: 5px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	padding: 8px;
	width: 150px;
}

.popover-button {
	color: #fff;
	background: #4a4c50;
	border: none;
	padding: 10px;
	margin-bottom: 5px;
	border-radius: 3px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
	transition: background-color 0.3s;
	font-size: 14px;
	font-weight: 300;
}

.popover-button:hover {
	background-color: #686b70;
}

.popover-button:last-child {
	margin-bottom: 0;
}

.selected {
	background-color: #686b70;
}

.category-title-container {
	white-space: nowrap;
}

.category-title-count {
	display: inline-block;
	color: #c4c4c4;
	font-size: 12px;
	margin-top: 5px;
}

.category-title-name {
	display: inline-block;
	margin-right: 10px;
}

html[dir="rtl"] .category-title-name {
	margin-right: 0px !important;
    margin-left: 10px !important;
}

.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .large-feature-game .feature-title {
	display: grid;
	align-items: start;
	gap: 2.5px;
	padding-top: 6px;
	grid-template-columns: none;
	grid-template-rows: auto auto;
}

.casino-game-area .casino-game-section .casino-feature-games .casino-feature-outer .feature-games-main .featured-game-inner .feature-title {
	display: grid;
	align-items: start;
	gap: 0px;
	padding-top: 6px;
	grid-template-columns: none;
	grid-template-rows: auto auto;
}

.provider-name {
	display: inline-block;
	color: #c4c4c4;
}

.fs-12px {
	font-size: 12px;
}

.fs-10px {
	font-size: 9px;
}

.view-all-container {
	display: flex;
	align-items: center;
	padding-top: 14px;
}

.view-all-divider {
	content: "";
	width: 100%;
	height: 1px;
	background: #4E545D;
	position: relative;
	margin-right: 10px;
}

html[dir="rtl"] .view-all-divider {
	margin-right: 0 !important;
	margin-left: 10px !important;
}

.h-30px {
	height: 30px;
}

.fw-300 {
	font-weight: 300;
}

.btn-small {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	font-weight: 300;
}

@media (max-width: 768px) {
	.btn-small {
		font-size: 12px !important;
	}
}
</style>
